<template>
  <div
    class="content-container container"
    :class="{
      'content-iframe': isInIframe,
    }"
  >
    <div class="footer-logo">
      <span>Ofrecido por</span>
      <img src="@/assets/img/prohipotecas-logo-small.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLogo',
  props: {
    isInIframe: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.footer-logo {
  padding: 10px !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;

  span {
    margin-right: 5px;
  }
  img {
    height: 18px;
  }
}
</style>